import React, {useState} from 'react';
import logo from './assets/images/logoVoltText.svg';
import './App.css';
import axios from "axios";

import { Row, Col, Image, Container, Button, Form } from 'react-bootstrap';

function App() {
  const [name, setName] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [email, setEmail] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [network, setNetwork] = useState(null);

  const [answerState, setAnswerState] = useState(1);
  const [showAlert, setShowAlert] = useState('none');

  const checkName = () => {
    if(name === null){
      setShowAlert('block');
    } else {
      setShowAlert('none');
      setAnswerState(3);
    }
  }

  const checkTwitter = () => {
     //window.open('https://twitter.com/DegenDistillery', '_blank');
    setAnswerState(4);
  }

  const checkEmail = () => {
    if(email === null){
      setShowAlert('block');
    } else {
      setShowAlert('none');
      setAnswerState(5);
    }
  }

  const checkWallet = () => {
    if(walletAddress === null){
      setShowAlert('block');
    } else {
      setShowAlert('none');
      setAnswerState(6);
    }
  }

  const checkNetwork = () => {
    axios.get('https://www.degendistillery.io/rest/api/postSubscription.php?email='+email+"&wallet="+walletAddress+"&name="+name+"&twitter="+twitter+"&network="+network+"&source=Signup")
    .then(res=> {
        console.log(res);
    })
    setAnswerState(7);
  }

  const checkLast = () => {
    window.open('https://www.degendistillery.com');
  }


  return (
    <div className="App">
      <Container>
        <Image src={logo} className='logoVolt' />
        <div className='questionContainer'>

          {answerState === 1 &&
          <>
          {/* DOMANDA 1 */}
          <Row>
            <Col xs={12}>
              <h2>SOMETHING’S DISTILLING</h2>
              <h4>Ready to step behind the velvet rope?</h4>
            </Col>
            <Col className="mt-3 mx-auto" xs={12} md={6}>
              <Button className='degenButton' onClick={() => setAnswerState(2)}>Click here</Button>
            </Col>
          </Row>
          </>
          }

          {answerState === 2 &&
          <>
          {/* DOMANDA 2 */}
          <Row>
            <Col xs={12}>
              <h2>What’s your name?</h2>
              <h4>You might win a spot on our Guestlist.</h4>
            </Col>
            <Col className="mt-3 mx-auto" xs={12} md={6}>
              <Form.Control type="text" placeholder="Enter your name (required)" className='textInput' value={name} onChange={(e) => setName(e.target.value)} />
              <Button className='degenButton mt-3' onClick={() => checkName()}>Next</Button>
              <p className='mt-3' style={{display: showAlert}}>This field is required!</p>
            </Col>
          </Row>
          </>
          }

          {answerState === 3 &&
          <>
          {/* DOMANDA 3 */}
          <Row>
            <Col xs={12}>
              <h2>Thanks {name.trim().split(' ')[0]}. If you have one, what’s your twitter handle?</h2>
            </Col>
            <Col className="mt-3 mx-auto" xs={12} md={6}>
              <Form.Control type="text" placeholder="Enter your twitter username (optional)" className='textInput' value={twitter} onChange={(e) => setTwitter(e.target.value)} />
              <Button className='degenButton mt-3' onClick={() => checkTwitter()}>Next</Button>
            </Col>
          </Row>
          </>
          }

          {answerState === 4 &&
          <>
          {/* DOMANDA 4 */}
          <Row>
            <Col xs={12}>
              <h2>Nearly there {name.trim().split(' ')[0]}. What’s your e-mail address?</h2>
            </Col>
            <Col className="mt-3 mx-auto" xs={12} md={6}>
              <Form.Control type="email" placeholder="Enter your email (required)" className='textInput' value={email} onChange={(e) => setEmail(e.target.value)} />
              <Button className='degenButton mt-3' onClick={() => checkEmail()}>Next</Button>
              <p className='mt-3' style={{display: showAlert}}>This field is required!</p>
            </Col>
          </Row>
          </>
          }

          {answerState === 5 &&
          <>
          {/* DOMANDA 5 */}
          <Row>
            <Col xs={12}>
              <h2>Drop your wallet address below</h2>
            </Col>
            <Col className="mt-3 mx-auto" xs={12} md={6}>
              <Form.Control type="text" placeholder="Enter your wallet address (required)" className='textInput' value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} />
              <Button className='degenButton mt-3' onClick={() => checkWallet()}>Next</Button>
              <p className='mt-3' style={{display: showAlert}}>This field is required!</p>
            </Col>
          </Row>
          </>
          }          

          {answerState === 6 &&
          <>
          {/* DOMANDA 6 */}
          <Row>
            <Col xs={12}>
              <h2>Did a member of our team/network invite you?</h2>
              <h4>If so, who?</h4>
            </Col>
            <Col className="mt-3 mx-auto" xs={12} md={6}>
              <Form.Control type="text" placeholder="Enter your answer (optional)" className='textInput' value={network} onChange={(e) => setNetwork(e.target.value)} />
              <Button className='degenButton mt-3' onClick={() => checkNetwork()}>Next</Button>
            </Col>
          </Row>
          </>
          }  

          {answerState === 7 &&
          <>
          {/* DOMANDA 7 */}
          <Row>
            <Col xs={12}>
              <h2>Congrats {name.trim().split(' ')[0]}. You’re one step closer to joining our select group of disruptors, tastemakers and party starters.</h2>
              <h4 className='mt-3'>If you haven’t been invited yet, the only way is to interact with our Twitter posts <a href='https://twitter.com/DegenDistillery' style={{color:'#D6FF01', textDecoration: 'none'}} target='_blank' rel="noreferrer">here</a></h4>
            </Col>
            <Col className="mt-3 mx-auto" xs={12} md={6}>
              <p>Now, explore more!</p>
              <Button className='degenButton' onClick={() => checkLast()}>HERE</Button>
            </Col>
          </Row>
          </>
          }  

        </div>

      </Container>
    </div>
  );
}

export default App;
